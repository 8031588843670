import React from "react";
import logo from "../logo.jpg"
import logo2 from "../logo2.png"
import wfs from "../wfs.svg"
import vpp from "../vpp.png"
import lens from "../lens2.png"
import marting from "../marting.png"
import pegasus from "../pegasus.png"
import defontana from "../defontana-logo.png"
import logiscore from "../logiscore-logo.png"
import logo4 from "../logo4.png"
import multivende from "../Multivende.png"
import Shopify from "../Shopify.png"
import { BsFillGearFill, BsClock } from "react-icons/bs"
import wp from "../WP.webp"
import AliceCarousel from 'react-alice-carousel';
import { Button, BackTop, Collapse } from 'antd';
import { GoLocation } from "react-icons/go";
import { FaGasPump } from "react-icons/fa";
import {MdSecurity} from "react-icons/md";

const { Panel } = Collapse;


class HomePage extends React.Component {
    constructor(props) {
        super(props)
        this.props = {

        }
    }
    componentDidMount() {

    }
    render() {
        const handleDragStart = (e) => e.preventDefault();

        const items = [
            <div style={{ minWidth: "450px", }}>
                <img src={wfs} height="150" onDragStart={handleDragStart} role="presentation" />
            </div>,
            <div style={{ minWidth: "150px" }}>
                <img src={lens} height="150" onDragStart={handleDragStart} role="presentation" />
            </div>,
            <div style={{ minWidth: "150px" }}>
                <img src={marting} height="150" onDragStart={handleDragStart} role="presentation" />
            </div>,
            <div style={{ minWidth: "150px" }}>
                <img src={pegasus} height="150" onDragStart={handleDragStart} role="presentation" />
            </div>,
            <div style={{ minWidth: "150px" }}>
                <img src={vpp} height="150" onDragStart={handleDragStart} role="presentation" />
            </div>


        ];
        return (
            <div className="hp_background">
                <BackTop />
                <div className="hp_header">
                    <img src={logo} className="hp_logo" />
                    <Button type="primary" title="" shape="round" size="large" href="mailto:contacto@telematicspartner.cl">
                        <div className="button_text">Contactanos</div>
                    </Button>
                </div>
                <div className="hp_upper">
                    <div className="hp_half">
                        <div className="first_title">REFERENTES EN SOLUCIONES DE SOFTWARE INNOVADORAS</div>
                    </div>
                </div>
                <div className="hp_mid1">
                    <div className="hp_half">

                    </div>
                    <div className="hp_half">
                        Desarrollo de Software
                        <p>
                            En Telematics Partner desarrollamos sistemas a la medida para tu empresa, aportando
                            experiencia y las mejores prácticas para llegar a la solución que necesitas.<br />
                            En Telematics Partner nos preocupamos que los sistemas que desarrollamos se integren con los
                            sistemas que ya posee tu empresa para lograr sinergias y eficiencias.
                        </p>
                    </div>
                </div>
                <div className="hp_mid3">

                    <div className="hp_half">
                        Integraciones
                        <p>
                            En Telematics Parnert desarrollamos integraciones a la medida entre sistemas que permitan
                            conectividad a través de API (Application Programming Interfaces).
                            Experiencia en integración de e-commers y ERP
                        </p>
                    </div>
                    <div className="hp_half">
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                            <img style={{ margin: "10px", height: "4.2vw" }} src={defontana} />
                            <img style={{ margin: "10px", height: "4.2vw" }} src={Shopify} />
                            <img style={{ margin: "10px", height: "4.2vw" }} src={multivende} />
                            <img style={{ margin: "10px", height: "4.2vw" }} src={logiscore} />
                            <img style={{ margin: "10px", height: "4.2vw" }} src={logo4} />
                        </div>

                    </div>
                </div>
                <div className="hp_mid2">


                    <div className="hp_half">
                        <img src={wp} />

                    </div>
                    <div className="hp_half">
                        Partner de Webfleet Solutions
                        <p>
                            En Telematics Partner Integramos WEBFLEET con el software de tu
                            Empresa y desarrollamos aplicaciones a tu medida para aumentar
                            la eficiencia de tu negocio.<br/> Experiencia en integración de datos para empresas como Arauco, Minico, Ready Mix, Sitrack, Samtech y más.
                        </p>
                    </div>
                </div>
                <Collapse defaultActiveKey={[1]}>
                    <Panel header="Conoce WEBFLEET" key="1" className="hp_collapse">
                        <div className="hp_mid5">
                            <div className="hp_mid5_title">
                                WEBFLEET es empresa líder en software de <a href="https://www.webfleet.com/es_cl/webfleet/fleet-management/" target="__blank">administración de flotas</a> y
                                seguimiento de vehículos en la nube.
                            </div>

                            <div className="hp_mid5_subtitle">
                                Realiza un seguimiento de los vehículos de tu empresa y
                                ahorra como un profesional con WEBFLEET
                            </div>
                            <div className="hp_mid5_items">
                                <div className="hp_mid5_container">
                                    <span>
                                        <GoLocation />
                                    </span>
                                    <div className="hp_mid5_item_title">
                                        Conoce dónde están los vehículos de tu empresa
                                    </div>
                                    <p>

                                        De manera ininte­rrumpida mediante el rastreo y seguimiento en tiempo real
                                    </p>
                                </div>
                                <div className="hp_mid5_container">
                                    <span>
                                        <FaGasPump />
                                    </span>
                                    <div className="hp_mid5_item_title">
                                        Ahorra en costos de combustible y mantenimiento
                                    </div>
                                    <p>
                                        Supervisando y analizando el comportamiento de conducción
                                    </p>
                                </div>
                                <div className="hp_mid5_container">
                                    <span>
                                        <BsFillGearFill />
                                    </span>
                                    <div className="hp_mid5_item_title">
                                        Realizamos la integración con el software en tu empresa
                                    </div>
                                    <p>
                                        Para optimizar todos los procesos de tu negocio
                                    </p>
                                </div>
                                <div className="hp_mid5_container">
                                    <span>
                                        <BsClock />
                                    </span>
                                    <div className="hp_mid5_item_title">
                                        Disminuye el tiempo de conducción y optimiza las rutas de entrega
                                    </div>
                                    <p>
                                        Con navegación profesional e información de tráfico en tiempo real
                                    </p>
                                </div>
                                <div className="hp_mid5_container">
                                    <span>
                                        <MdSecurity />
                                    </span>
                                    <div className="hp_mid5_item_title">
                                        Optimiza el desempeño y la seguridad de tus conductores
                                    </div>
                                    <p>
                                        Controlando conductas de riesgo en la conducción
                                    </p>
                                </div>
                            </div>

                        </div >
                    </Panel >
                </Collapse >
                <div className="hp_mid4">
                    Creando más que solo soluciones informáticas
                    <p>Algunos de nuestros clientes</p>
                    <AliceCarousel disableButtonsControls={true} disableDotsControls={true} animationDuration={1600} infinite={true} autoPlay={true} responsive={{ 0: { items: 3 } }} mouseTracking items={items} />
                </div>
                <div className="hp_footer">
                    <div className="hp_footer_1">
                        <img src={logo} style={{ width: "250px" }}></img>
                        <table>
                            <tr>
                                <td style={{ width: "100px" }}>Dirección</td>
                                <td style={{ width: "20px" }}>:</td>
                                <td>Pedro Lira 115, Los Angeles, Chile</td>
                            </tr>
                            <tr>
                                <td>WhatsApp</td>
                                <td>:</td>
                                <td>+56 9 3905 2858</td>
                            </tr>
                            <tr>
                                <td>Correo</td>
                                <td>:</td>
                                <td>contacto@telematicspartner.cl</td>
                            </tr>
                        </table>


                    </div>
                    <div className="hp_footer_2">
                        <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                            <iframe style={{ width: "100%", height: "50%" }} title="mapa" src="https://maps.google.com/?q=-37.479873,-72.342783&z=16&t=m&output=embed" className="mapa"></iframe>
                        </div>

                        <div style={{ display: "flex", minHeight: "fit-content", width: "100%", bottom: "0%", borderTop: "3px solid black", borderBottom: "3px solid black", color: "black", flexDirection: "column" }}>
                            © 2022 Telematics Partner SPA. Todos los derechos reservados.

                        </div>
                    </div>
                    <div className="hp_footer_3">
                        <img src={logo2} style={{ width: "300px" }}></img>
                    </div>
                </div>
            </div >
        );
    }

}


export default HomePage;